import { omit } from "lodash";
import {
	SET_PRICE_FILTER,
	SET_STAR_FILTER,
	SET_REVIEW_FILTER,
	SET_BOUNDS_FILTER,
	SET_NEIGHBORHOODS_FILTER,
	SET_PROPERTY_FILTER,
	CLEAR_PROPERTY_FILTER
} from "../actions/filters";
import { SET_LOCATION } from "../actions/options";

const defaultFilters = {
	stars: {},
	reviews: {},
	minRating: 0,
	minPrice: 0,
	maxPrice: 0,
	properties: {},
	neighborhoods: {},
	bounds: undefined
};

export default function filtersReducer(state = defaultFilters, action) {
	switch (action.type) {
		case SET_PRICE_FILTER:
			return {
				...state,
				minPrice: action.minPrice,
				maxPrice: action.maxPrice
			};

		case SET_STAR_FILTER:
			return {
				...state,
				stars: action.selected
					? {
							...state.stars,
							[action.rating]: action.selected
					  }
					: omit(state.stars, [action.rating])
			};

		case SET_REVIEW_FILTER:
			return {
				...state,
				reviews: action.selected
					? {
							...state.reviews,
							[action.rating]: action.selected
					  }
					: omit(state.reviews, [action.rating])
			};

		case SET_BOUNDS_FILTER:
			return {
				...state,
				bounds: action.bounds
			};

		case SET_NEIGHBORHOODS_FILTER:
			return {
				...state,
				neighborhoods: action.neighborhoods
			};

		case SET_PROPERTY_FILTER:
			return {
				...state,
				properties: action.selected
					? {
							...state.properties,
							[action.property.id]: action.selected
					  }
					: omit(state.properties, [action.property.id])
			};

		case CLEAR_PROPERTY_FILTER:
			return {
				...state,
				properties: {}
			};

		// Reset filters when changing locations
		case SET_LOCATION:
			return defaultFilters;

		default:
			return state;
	}
}
