import { SET_SUGGESTIONS } from "../actions/locationSuggestions";

export default function locationSuggestionsReducer(state = {}, action) {
	switch (action.type) {
		case SET_SUGGESTIONS:
			return {
				...state,
				[action.input]: action.suggestions
			};

		default:
			return state;
	}
}
