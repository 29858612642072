import { SIGN_IN_SUCCESS, SIGN_OUT } from "../actions/auth";
import { FETCH_PROFILE_SUCCESS } from "../actions/user";

export default function userReducer(state = {}, action) {
	switch (action.type) {
		case SIGN_IN_SUCCESS:
			return action.user;
		case FETCH_PROFILE_SUCCESS:
			return action.profile;
		case SIGN_OUT:
			return {};
		default:
			return state;
	}
}
