import { connect } from "react-redux";
import PureComponent from "./component";
import { openModal } from "../../actions/modal";
import { closeDropdown } from "../../actions/dropdown";
import { signOut } from "../../actions/auth";
import { fetchProfile } from "../../actions/user";

const mapStateToProps = (state, ownProps) => ({
	user: state.user,
	token: state.auth.token,
	tokenExpiration: state.auth.expiration
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	openModal: id => openModal(id, dispatch),
	closeDropdown: () => closeDropdown(dispatch),
	signOut: () => signOut(dispatch),
	fetchProfile: token => fetchProfile(token, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
