import { getProperty } from "../api";
import mixpanel from "mixpanel-browser";

export const HOTEL_SUCCESS = "HOTEL_SUCCESS";
export const HOTEL_FAILURE = "HOTEL_FAILURE";

export function fetchHotel(hotelId, dispatch) {
	if (process.env.NODE_ENV !== "test") {
		mixpanel.track("View Property", { hotelId });
	}
	return getProperty(hotelId)
		.then(hotel => {
			dispatch({ type: HOTEL_SUCCESS, hotel });
			return hotel;
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: HOTEL_FAILURE, hotelId });
		});
}
