import qs from "qs";
import { differenceInDays } from "date-fns";
import { sanitizeImage, getImageCaption, sanitizeDescription } from "./utils";

export function search(options) {
	const { location, checkInDate, checkOutDate, rooms, starRatings } = options;
	const no_Of_persons = rooms[0].noOfAdults + rooms[0].noOfChildren;
	return post("services/getPropertyCity", {
		city: location,
		checkInDate,
		checkOutDate,
		no_Of_persons,
		pageNo: 1,
		sortingId: "price",
		rooms,
		minPrice: 0,
		maxPrice: 10000,
		minGuestRating: 0,
		maxGuestRating: 5,
		starRatings
	}).then(res => {
		return res.properties.map(property => {
			const dayrate = property.dayrateSearchPageDTO;
			const room = dayrate.roomtypeDTO || {};
			return {
				rateKey: dayrate.rateKey,
				rateCode: dayrate.rateCode,
				source: dayrate.source,
				supplierType: dayrate.supplierType,
				price: parseFloat(dayrate.price),
				marketPrice: parseFloat(
					dayrate.propertySearchPageDTO.showMarketPrice
				),
				resortFee: parseFloat(dayrate.resortFee),
				property: {
					id: dayrate.propertySearchPageDTO.propertyId,
					expediaId: dayrate.propertySearchPageDTO.expediaHotelId,
					name: dayrate.propertySearchPageDTO.propertyName,
					lat: parseFloat(dayrate.propertySearchPageDTO.latitude),
					lng: parseFloat(dayrate.propertySearchPageDTO.longitude),
					propertyType: dayrate.propertySearchPageDTO.propertyType,
					zoneName: dayrate.propertySearchPageDTO.zoneName,
					userRating: parseFloat(
						dayrate.propertySearchPageDTO.guestRating
					),
					starRating: parseFloat(
						dayrate.propertySearchPageDTO.starRating
					),
					reviews: dayrate.propertySearchPageDTO.trustReviews || {},
					imageURL: sanitizeImage(
						dayrate.propertySearchPageDTO.hotelImageIntUrl
					),
					imageLargeURL: sanitizeImage(
						dayrate.propertySearchPageDTO.hotelImageIntUrl,
						true
					)
				},
				tax: dayrate.propertyTotalTaxDTO || {},
				room: {
					roomTypeId: dayrate.roomTypeId,
					roomTypeHistoryId: room.roomTypeHistoryId,
					roomTypeCode: room.roomCode, // Is this right?
					displayName: room.displayName,
					refundable: !dayrate.nonRefundable,
					cancellationPolicy: dayrate.cancellationPolicyString
				},
				roomsRateInfo: dayrate.roomsRateInfo || {}
			};
		});
	});
}

export function locationSuggestions(input) {
	return post("services/citySuggestions", {
		city: input
	})
		.then(res => {
			return res.stringList.slice(0, 8);
		})
		.catch(err => console.error(err));
}

export function getProperty(hotelId) {
	return get(`services/getProperty/${hotelId}`).then(res => {
		return {
			id: res.propertyId,
			expediaId: res.expediaHotelId,
			name: res.propertyName,
			city: res.city,
			address: {
				street: res.address.address1,
				city: res.address.cityName,
				state: res.address.stateName
			},
			lat: parseFloat(res.latitude),
			lng: parseFloat(res.longitude),
			userRating: parseFloat(res.guestRating),
			starRating: parseFloat(res.starRating),
			images: res.images.map(URL => ({
				imageURL: sanitizeImage(URL),
				imageLargeURL: sanitizeImage(URL, true),
				caption: getImageCaption(URL)
			})),
			reviews: res.trustReviews
		};
	});
}

export function getRooms(hotel, options) {
	const { checkInDate, checkOutDate, rooms } = options;
	const numberOfNights = differenceInDays(checkOutDate, checkInDate);

	const getRoomTypeCode = ({
		propertyType,
		hotelBedsRoomCode,
		touricoRoomTypeId,
		roomTypeId
	}) => {
		if (propertyType === 4) {
			return touricoRoomTypeId;
		}
		if (propertyType === 3) {
			return hotelBedsRoomCode;
		}
		return roomTypeId;
	};

	return post("services/rooms/fchrooms/", {
		pId: hotel.id,
		eID: hotel.expediaId, // Why ID and Id ???
		checkInDate,
		checkOutDate,
		rooms
	}).then(res => {
		return res.map(room => ({
			displayName: room.displayName,
			nightlyPrice: parseFloat(room.price),
			price: parseFloat(room.roomsRateInfo[0].roomPrice),
			tax: parseFloat(room.roomsRateInfo[0].roomTax),
			resortFee: parseFloat(room.resortFee),
			nightlyResortFee: parseFloat(room.resortFee) / numberOfNights,
			totalPrice: parseFloat(room.roomsRateInfo[0].roomTotalAmount),
			source: room.source,
			supplierType: room.supplierType,
			propertyType: room.propertyType,
			rateKey: room.rateKey,
			rateCode: room.rateCode,
			roomTypeId: room.roomTypeId,
			roomTypeCode: getRoomTypeCode(room),
			refundable: !room.nonRefundable,
			termsAndConditions: sanitizeDescription(
				room.termsAndConditions.description
			),
			cancellationPolicy: room.cancellationPolicyString,
			images: (room.roomTypeImageList || []).map(imageObj =>
				sanitizeImage(imageObj.imageUrl)
			),
			roomsRateInfo: room.roomsRateInfo
		}));
	});
}

// User

export function createUser(firstName, lastName, email, password) {
	if (!firstName || !lastName || !email || !password)
		throw new Error("Missing params");

	const params = qs.stringify({
		firstName,
		lastName,
		email,
		userName: email,
		password
	});

	return post(`services/createProfile?${params}`);
}

export function getProfile(token) {
	return get("services/user/getUserProfile", token);
}

export function updateProfile(user, token) {
	return post(
		"services/user/updateUserProfile",
		{
			firstName: user.firstName,
			lastName: user.lastName,
			phoneNumber: user.phoneNumber || "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			countryName: "US"
		},
		token
	).then(response => {
		return response;
	});
}

export function updatePassword(email, password, token) {
	return post("services/user/resetPassword", { email, password }, token).then(
		response => {
			return response;
		}
	);
}

// Reservations

export function getReservationsApi(token) {
	return get("services/user/reservations", token).then(res =>
		res.userReservations.map(({ reservationInfo, propertyInfo }) => ({
			confirmationCode: reservationInfo.htConfirmationCode,
			checkInDate: reservationInfo.checkInDate,
			checkOutDate: reservationInfo.checkOutDate,
			checkInTime: propertyInfo.checkInTime,
			checkOutTime: propertyInfo.checkOutTime,
			city: propertyInfo.city,
			property: {
				id: propertyInfo.id,
				name: propertyInfo.propertyName,
				imageURL: sanitizeImage(propertyInfo.hotelImageUrl),
				imageLargeURL: sanitizeImage(propertyInfo.hotelImageUrl, true),
				latitude: propertyInfo.latitude,
				longitude: propertyInfo.longitude,
				address: propertyInfo.address,
				starRating: propertyInfo.starRating,
				reviewCount: propertyInfo.reviewCount
			},
			rooms: [
				{
					noOfAdults: parseInt(
						reservationInfo.roomInfos[0].noOfAdults
					),
					noOfChildren: parseInt(
						reservationInfo.roomInfos[0].noOfChildren
					),
					childrenAges: reservationInfo.roomInfos[0].childrenAges
				}
			],
			guestNames: [
				`${reservationInfo.roomInfos[0].guestFirstName} ${reservationInfo.roomInfos[0].guestLastName}`
			],
			roomType: reservationInfo.roomName,
			cardDetails: reservationInfo.cardDetails,
			refundable: reservationInfo.isCancellable,
			cancellationPolicy: reservationInfo.cancellationPolicy,
			termsAndConditions: reservationInfo.termsAndConditions,

			roomCharges: parseFloat(reservationInfo.roomInfos[0].price),
			taxCharges: parseFloat(reservationInfo.roomInfos[0].tax),
			totalCharges: parseFloat(reservationInfo.roomInfos[0].total),
			resortFee: parseFloat(reservationInfo.resortFee)
		}))
	);
}

export function getReservationApi(confirmationCode, token) {
	return get(`services/user/reservations/${confirmationCode}`, token).then(
		response => {
			const reservation = response.userReservationDTO.reservationInfo;
			const property = response.userReservationDTO.propertyInfo;
			const { roomInfos } = reservation;
			const roomInfo = roomInfos[0];
			const { guestFirstName, guestLastName } = roomInfo;
			const guestName = `${guestFirstName} ${guestLastName}`;
			return {
				confirmationCode: reservation.htConfirmationCode,
				checkInDate: reservation.checkInDate,
				checkOutDate: reservation.checkOutDate,
				checkInTime: property.checkInTime,
				checkOutTime: property.checkOutTime,
				guestNames: [guestName],
				roomType: reservation.roomName,
				cardDetails: reservation.cardDetails,
				refundable: reservation.isCancellable,
				cancellationPolicy: reservation.cancellationPolicy,
				termsAndConditions: reservation.termsAndConditions,
				roomCharges: parseFloat(roomInfo.price),
				taxCharges: parseFloat(roomInfo.tax),
				totalCharges: parseFloat(roomInfo.total),
				resortFee: parseFloat(reservation.resortFee),
				rooms: [
					{
						noOfAdults: parseInt(roomInfo.noOfAdults),
						noOfChildren: parseInt(roomInfo.noOfChildren),
						childrenAges: roomInfo.childrenAges
					}
				],
				property: {
					name: property.propertyName,
					imageURL: sanitizeImage(property.hotelImageUrl),
					imageLargeURL: sanitizeImage(property.hotelImageUrl, true),
					latitude: property.latitude,
					longitude: property.longitude,
					address: property.address,
					starRating: parseInt(property.starRating)
				}
			};
		}
	);
}

// Payment

export function fetchSources(token) {
	return get("services/user/list-payment-sources", token).then(response => {
		const { error, paymentSources } = response;
		if (error) throw new Error(error);
		return paymentSources;
	});
}

export function deleteSource(sourceId, token) {
	return get(`services/user/delete-payment-source/${sourceId}`, token).then(
		response => {}
	);
}

export function stripePayment(paymentInfo, token) {
	const {
		property,
		room,
		options,
		guest,
		stripeToken,
		stripePaymentSourceId
	} = paymentInfo;
	if (!stripeToken && !stripePaymentSourceId) {
		console.error("Must include stripeToken or stripePaymentSourceId");
		return;
	}

	if (!token) {
		console.error("Missing token");
		return;
	}

	const paymentBody = createPaymentBody(property, room, options, guest);
	const paymentAmount = paymentBody.roomsDetails[0].roomRate.roomTotalAmount;
	const paymentInformation = {
		stripePaymentSourceId: "", // If no stripePaymentSourceId, backend will look for card number
		paymentAmount,
		saveCard: true // Assume we always want to save cards
	};

	if (stripeToken) {
		paymentInformation.stripePaymentToken = stripeToken;
	} else if (stripePaymentSourceId) {
		paymentInformation.stripePaymentSourceId = stripePaymentSourceId;
		paymentInformation.saveCard = false;
		// API needs these fields...
		// paymentInformation.cardNumber = "null";
		// paymentInformation.expiryMonth = "null";
		// paymentInformation.expiryYear = "null";
		// paymentInformation.firstName = "null";
		// paymentInformation.lastName = "null";
		// paymentInformation.address = "null";
		// paymentInformation.address2 = "null";
		// paymentInformation.postalCode = "null";
		// paymentInformation.city = "null";
		// paymentInformation.countryCode = "null";
		// paymentInformation.state = "null";
		// paymentInformation.securityCode = "null";
	}

	const body = {
		...paymentBody,
		paymentInformation
	};

	return post(`services/user/book`, body, token).then(response => {
		if (response.statusCode !== 200 || response.error) {
			throw new Error(response.error.errorMsg);
		}
		return response;
	});
}

export function authorizeNetPayment(
	property,
	room,
	options,
	guest,
	card,
	token
) {
	if (!card) {
		console.error("Missing card");
		return;
	}

	if (!token) {
		console.error("Missing token");
		return;
	}

	const paymentBody = createPaymentBody(property, room, options, guest);
	const paymentAmount = paymentBody.roomsDetails[0].roomRate.roomTotalAmount;
	const paymentInformation = createPaymentInformation(card, paymentAmount);
	const body = {
		...paymentBody,
		paymentInformation
	};

	return post(`services/user/book`, body, token).then(response => {
		if (response.statusCode !== 200 || response.error) {
			throw new Error(response.error.errorMsg);
		}
		return response;
	});
}

function createPaymentBody(property, room, options, guest) {
	if (
		property === undefined ||
		room === undefined ||
		options === undefined ||
		guest === undefined
	) {
		console.error("Missing params");
	}
	const { id, city } = property;
	const {
		roomTypeId,
		roomsRateInfo,
		source,
		resortFee,
		rateCode,
		roomTypeCode,
		displayName
	} = room;
	const { checkInDate, checkOutDate, rooms } = options;
	const { firstName, lastName, emailAddress } = guest;

	const { noOfAdults, noOfChildren, childrenAges } = rooms[0];

	if (id === undefined || city === undefined) {
		console.error("Invalid property", property);
	}
	if (
		roomTypeId === undefined ||
		roomsRateInfo === undefined ||
		source === undefined
		// resortFee === undefined ||
		// rateCode === undefined
		// roomTypeCode === undefined ||
		// displayName === undefined
	) {
		console.error("Invalid room", room);
	}
	if (
		checkInDate === undefined ||
		checkOutDate === undefined ||
		rooms === undefined
	) {
		console.error("Invalid options", options);
	}
	if (
		firstName === undefined ||
		lastName === undefined ||
		emailAddress === undefined
	) {
		console.error("Invalid guest", guest);
	}
	if (
		noOfAdults === undefined ||
		noOfChildren === undefined ||
		childrenAges === undefined
	) {
		console.error("Invalid rooms options", rooms[0]);
	}

	// const { totalTax, propertyTaxes, totalTaxDisplay } = propertyTotalTaxDTO;

	const roomsDetails = roomsRateInfo.map(room => {
		const { roomPrice, roomTax, roomTotalAmount, rateKey } = room;
		if (
			roomPrice === undefined ||
			roomTax === undefined ||
			roomTotalAmount === undefined
		)
			console.error("Invalid roomsRateInfo room", room);
		return {
			roomRate: {
				roomPrice,
				roomTax,
				roomTotalAmount,
				rateKey
			},
			roomOccupancy: {
				firstName,
				lastName,
				email: emailAddress,
				numberOfAdults: noOfAdults,
				numberOfChildren: noOfChildren,
				childrenAges
			}
		};
	});

	return {
		checkInDate,
		checkOutDate,
		source,
		htRoomId: roomTypeId,
		htPropertyId: id,
		resortFee,
		rateCode,
		roomTypeCode,
		roomName: displayName,
		roomsDetails
	};
}

function createPaymentInformation(card, paymentAmount) {
	const {
		cardNumber,
		expiry,
		cvc,
		billingName
		// billingAddress,
		// billingCity,
		// billingState,
		// billingZip
	} = card;
	const [billingFirstName, billingLastName] = billingName.split(" ");
	const [expiryMonth, expiryYear] = expiry.split(" / ");
	const fourDigitExpiryYear = `20${expiryYear}`;

	return {
		cardNumber: cardNumber.replace(/ /g, ""),
		expiryMonth,
		expiryYear: fourDigitExpiryYear,
		firstName: billingFirstName,
		lastName: billingLastName,
		// address: billingAddress,
		// address2: "",
		// postalCode: billingZip,
		// city: billingCity,
		// countryCode: "US",
		// state: billingState,
		// Only works with this fake billing info...
		address: "travelnow",
		address2: "",
		postalCode: "10017",
		city: "new york city",
		countryCode: "US",
		state: "new jesrsey test",
		securityCode: cvc,
		paymentAmount
	};
}

// Auth

export function getToken(email, password) {
	const params = qs.stringify({
		username: email,
		password,
		grant_type: "password",
		client_id: "restapp",
		client_secret: "restapp"
	});
	return post(`oauth/token?${params}`).then(response => {
		const json = JSON.parse(response);
		if (!json.value) {
			throw Error("Sign in failed. Please try again.");
		}

		return {
			token: json.value,
			expiration: json.expiration,
			refreshToken: json.refreshToken
		};
	});
}

export function sendPasswordReset(email) {
	return get(`services/sendPasswordReset/${email}`).then(response => {
		if (response.msg !== "Email has been sent with four digit pin.")
			throw Error(response.msg);
		return response;
	});
}

export function checkCode(email, password, code) {
	return post("services/checkResetPassword", {
		email,
		code
	}).then(response => {
		return response;
	});
}

export function resetPassword(email, password) {
	return post("services/resetPassword2", { email, password });
}

// Helpers

function get(path, token) {
	let headers = {};
	if (token) {
		headers["X-AuthToken"] = token;
		headers["Content-Type"] = "application/json";
	}

	return fetch(process.env.REACT_APP_API_ROOT + path, {
		headers
	}).then(response => {
		if (response.status === 401) {
			throw Error("Unauthorized");
		}

		try {
			const jsonPromise = response.json();
			return jsonPromise;
		} catch (error) {
			console.error(error);
		}
	});
}

function post(path, data, token) {
	if (data) {
		return postJSON(path, data, token).then(response => {
			if (response.status === 401) {
				throw Error("Unauthorized");
			}
			try {
				const jsonPromise = response.json();

				// if (!response.ok) {
				// 	throw Error(response.statusText);
				// }

				return jsonPromise;
			} catch (error) {
				console.error(error);
			}
		});
	} else {
		return postForm(path, token).then(response => {
			if (response.status === 401) {
				throw Error("Unauthorized");
			}

			const textPromise = response.text();

			if (!response.ok) {
				throw Error(response.statusText);
			}

			return textPromise;
		});
	}
}

function postJSON(path, data, token) {
	let headers = {
		"content-type": "application/json"
	};
	if (token) {
		headers["X-AuthToken"] = token;
	}
	return fetch(process.env.REACT_APP_API_ROOT + path, {
		headers,
		body: JSON.stringify(data),
		method: "POST"
	});
}

function postForm(path, token) {
	let headers = {
		"content-type": "application/x-www-form-urlencoded; charset=utf-8"
	};

	if (token) {
		headers["X-AuthToken"] = token;
	}

	return fetch(process.env.REACT_APP_API_ROOT + path, {
		headers,
		method: "POST"
	});
}
