import {
	PAYMENT_START,
	PAYMENT_SUCCESS,
	PAYMENT_FAILURE,
	SOURCES_SUCCESS,
	CLEAR_ERROR
} from "../actions/payment";

export default function authReducer(
	state = {
		paymentMethod: undefined,
		sources: undefined,
		loading: false,
		error: undefined
	},
	action
) {
	switch (action.type) {
		case PAYMENT_START:
			return {
				...state,
				loading: true,
				error: undefined
			};

		case PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				error: undefined
			};

		case PAYMENT_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};

		case SOURCES_SUCCESS:
			return {
				...state,
				sources: action.sources
			};

		case CLEAR_ERROR:
			return {
				...state,
				error: undefined
			};

		default:
			return state;
	}
}