import { SELECT_ROOM } from "../actions/book";

export default function authReducer(
	state = {
		selectedRoom: undefined
	},
	action
) {
	switch (action.type) {
		case SELECT_ROOM:
			return { ...state, selectedRoom: action.room };
		default:
			return state;
	}
}
