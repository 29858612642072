export const OPEN_DROPDOWN = "OPEN_DROPDOWN";
export const CLOSE_DROPDOWN = "CLOSE_DROPDOWN";

export function openDropdown(id, dispatch) {
	dispatch({ type: OPEN_DROPDOWN, id });
}

export function closeDropdown(dispatch) {
	dispatch({ type: CLOSE_DROPDOWN });
}
