import {
	RESERVATIONS_START,
	RESERVATIONS_SUCCESS,
	RESERVATION_SUCCESS
} from "../actions/reservations";
import { SIGN_OUT } from "../actions/auth";

const defaultState = { reservations: [], loadingReservations: false };

export default function reservationsReducer(state = defaultState, action) {
	switch (action.type) {
		case RESERVATIONS_START:
			return {
				...state,
				loadingReservations: true
			};

		case RESERVATIONS_SUCCESS:
			return {
				...state,
				loadingReservations: false,
				reservations: action.reservations
			};

		case RESERVATION_SUCCESS:
			// Replace reservation
			return {
				...state,
				reservations: [
					...state.reservations.filter(
						reservation =>
							reservation.confirmationCode !==
							action.reservation.confirmationCode
					),
					action.reservation
				]
			};

		case SIGN_OUT:
			return defaultState;

		default:
			return state;
	}
}
