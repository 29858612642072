import React, { PureComponent } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { format, addDays, isFuture, isToday } from "date-fns";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/browser";

import rootReducer from "./reducers";

import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Home from "./components/Home";

import asyncComponent from "./components/AsyncComponent";
const asyncImports = {
	ResultsMap: () => import("./components/ResultsMap"),
	ResultsList: () => import("./components/ResultsList"),
	Filters: () => import("./components/Filters"),
	FiltersBar: () => import("./components/FiltersBar"),
	Book: () => import("./components/Book"),
	Reservation: () => import("./components/Reservation"),
	Gallery: () => import("./components/Gallery"),
	Terms: () => import("./components/Terms"),
	Privacy: () => import("./components/Privacy"),
	Hotel: () => import("./components/Hotel"),
	Account: () => import("./components/Account"),
	SignIn: () => import("./components/SignIn"),
	SignUp: () => import("./components/SignUp"),
	PasswordReset: () => import("./components/PasswordReset"),
	Backdrop: () => import("./components/Backdrop"),
	ScrollToTop: () => import("./components/ScrollToTop"),
	AirbnbStuff: () => import("./components/AirbnbStuff")
};

const ResultsMap = asyncComponent(asyncImports.ResultsMap);
const ResultsList = asyncComponent(asyncImports.ResultsList);
const Filters = asyncComponent(asyncImports.Filters);
const FiltersBar = asyncComponent(asyncImports.FiltersBar);
const Book = asyncComponent(asyncImports.Book);
const Reservation = asyncComponent(asyncImports.Reservation);
const Gallery = asyncComponent(asyncImports.Gallery);
const Terms = asyncComponent(asyncImports.Terms);
const Privacy = asyncComponent(asyncImports.Privacy);
const Hotel = asyncComponent(asyncImports.Hotel);
const Account = asyncComponent(asyncImports.Account);
const SignIn = asyncComponent(asyncImports.SignIn);
const SignUp = asyncComponent(asyncImports.SignUp);
const PasswordReset = asyncComponent(asyncImports.PasswordReset);
const Backdrop = asyncComponent(asyncImports.Backdrop);
const ScrollToTop = asyncComponent(asyncImports.ScrollToTop);

// Move search date to future if in past
const DateTransform = createTransform(
	(inboundState, key) => inboundState,
	(outboundState, key) => {
		const validCheckIn =
			isFuture(outboundState.checkInDate) ||
			isToday(outboundState.checkInDate);
		return {
			...outboundState,
			checkInDate: validCheckIn
				? outboundState.checkInDate
				: format(addDays(Date(), 14), "YYYY-MM-DD"),
			checkOutDate: validCheckIn
				? outboundState.checkOutDate
				: format(addDays(Date(), 16), "YYYY-MM-DD")
		};
	},
	{ whitelist: ["options"] }
);
const persistConfig = {
	key: "root",
	storage,
	transforms: [DateTransform],
	blacklist: [
		"book",
		"dropdown",
		"filters",
		"gallery",
		"modal",
		"payment",
		"reservations",
		"rooms",
		"search",
		"ui"
	]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.NODE_ENV
});

export default class App extends PureComponent {
	componentDidMount() {
		mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
		// window.Intercom("boot", {
		// 	app_id: process.env.REACT_APP_INTERCOM_APP_ID,
		// 	custom_launcher_selector: "#my_custom_link"
		// });

		// Load other components async (super hacky method)
		setTimeout(() => {
			Object.values(asyncImports).forEach(importFunc => importFunc());
		}, 1000);
	}

	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router>
						<ScrollToTop>
							<div className="sans-serif flex flex-column">
								<Route component={Nav} />

								<div className="flex flex-row-ns flex-column-reverse relative">
									<Route exact path="/" component={Home} />
									<Route path="/hotel" component={Hotel} />

									<Route
										exact
										path="/s/:location"
										component={FiltersBar}
									/>
									<Route
										exact
										path="/s/:location"
										component={ResultsList}
									/>
									<Route
										path="/(s|hotel)/"
										component={ResultsMap}
									/>
									<Route path="/book" component={Book} />
									<Route
										exact
										path="/reservations/:confirmationCode"
										component={Reservation}
									/>
									<Route
										exact
										path="/account"
										component={Account}
									/>
									<Route
										exact
										path="/terms"
										component={Terms}
									/>
									<Route
										exact
										path="/privacy"
										component={Privacy}
									/>
									<Route path="/" component={Backdrop} />
								</div>

								<Route path="/" component={Footer} />

								<Route path="/hotel" component={Gallery} />
								<Route component={SignIn} />
								<Route component={SignUp} />
								<Route component={PasswordReset} />
							</div>
						</ScrollToTop>
					</Router>
				</PersistGate>
			</Provider>
		);
	}
}
