import {
	SHOW_GALLERY,
	HIDE_GALLERY,
	SET_CURRENT_IMAGE
} from "../actions/gallery";

export default function galleryReducer(
	state = {
		showGallery: false,
		currentImage: 0
	},
	action
) {
	switch (action.type) {
		case SHOW_GALLERY:
			return {
				...state,
				showGallery: true
			};
		case HIDE_GALLERY:
			return {
				...state,
				showGallery: false
			};
		case SET_CURRENT_IMAGE:
			return {
				...state,
				currentImage: action.image
			};
		default:
			return state;
	}
}
