import React, { PureComponent } from "react";
import { DayPickerRangeController } from "react-dates";
import {
	START_DATE,
	END_DATE,
	VERTICAL_SCROLLABLE,
	HORIZONTAL_OREINTATION
} from "react-dates/constants";
import moment from "moment";
import { format, differenceInCalendarDays } from "date-fns";
import "./styles.css";

export default class DatePicker extends PureComponent {
	state = {
		focusedInput: START_DATE
	};

	componentDidMount() {
		const { options } = this.props;
		this.setState({ initialOptions: options });
	}

	render() {
		const { options, setDates, performSearch } = this.props;
		const { focusedInput } = this.state;
		const { checkInDate, checkOutDate } = options;
		const checkInDateMoment = moment(checkInDate);
		const checkOutDateMoment = moment(checkOutDate);
		return (
			<DayPickerRangeController
				initialVisibleMonth={
					window.innerWidth < 480 ? () => moment() : null
				}
				startDate={checkInDateMoment}
				endDate={checkOutDateMoment}
				focused={true}
				focusedInput={focusedInput}
				numberOfMonths={window.innerWidth < 480 ? 12 : 2}
				keepOpenOnDateSelect={true}
				hideKeyboardShortcutsPanel={true}
				monthFormat={"MMMM YYYY"}
				weekDayFormat={"dd"}
				orientation={
					window.innerWidth < 480
						? VERTICAL_SCROLLABLE
						: HORIZONTAL_OREINTATION
				}
				daySize={
					window.innerWidth < 480
						? Math.floor((window.innerWidth - 32) / 7)
						: undefined
				}
				isOutsideRange={day => {
					const now = new Date();
					const nowDiff = differenceInCalendarDays(day, now);
					const checkInDiff = differenceInCalendarDays(
						day,
						checkInDateMoment
					);
					if (nowDiff < 0) return true;
					if (checkInDate && checkOutDate) return false;
					return checkInDiff > 28;
				}}
				onFocusChange={focusedInput => this.setState({ focusedInput })}
				onDatesChange={({ startDate, endDate }) => {
					const startDateFmt = format(startDate, "YYYY-MM-DD");
					const endDateFmt = format(endDate, "YYYY-MM-DD");
					const startDateChanged =
						startDateFmt !== options.checkInDate;
					const newFocusedInput = startDateChanged
						? END_DATE
						: endDate
						? START_DATE
						: END_DATE;
					this.setState({ focusedInput: newFocusedInput });
					setDates(
						startDateFmt,
						startDateChanged ? null : endDateFmt
					);
				}}
				onClose={() => performSearch(options)}
			/>
		);
	}
}
