import {
	createUser,
	getToken,
	getProfile,
	sendPasswordReset,
	checkCode,
	resetPassword as resetPasswordAPI
} from "../api";
import { getReservations } from "./reservations";
import { fetchSources } from "./payment";

export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const SIGN_OUT = "SIGN_OUT";

export const CLEAR_ERROR = "CLEAR_ERROR";

export function signUp(firstName, lastName, email, password, dispatch) {
	dispatch({ type: SIGN_UP_START });
	return createUser(firstName, lastName, email, password)
		.then(response => {
			dispatch({ type: SIGN_UP_SUCCESS });
			signIn(email, password, dispatch);
		})
		.catch(error => {
			dispatch({
				type: SIGN_UP_FAILURE,
				error:
					"There was an issue creating your account. Have you already signed up with this email?"
			});
		});
}

export function signIn(email, password, dispatch) {
	dispatch({ type: SIGN_IN_START });
	return getToken(email, password)
		.then(({ token, expiration, refreshToken }) => {
			const fullToken = `bearer ${token}`;
			getProfile(fullToken).then(user => {
				dispatch({
					type: SIGN_IN_SUCCESS,
					token: fullToken,
					expiration,
					refreshToken,
					user
				});
				getReservations(fullToken, dispatch);
				fetchSources(fullToken, dispatch);
				if (window.FS) {
					window.FS.identify(user.userId, {
						name: `${user.firstName} ${user.lastName}`,
						email: email
					});
				}
			});
			return { token: fullToken, expiration, refreshToken };
		})
		.catch(error => {
			dispatch({
				type: SIGN_IN_FAILURE,
				error: "Sign in failed. Please try again."
			});
		});
}

export function googleLogin(token, expiration, dispatch) {
	const fullToken = `google ${token}`;
	return getProfile(fullToken).then(user => {
		dispatch({
			type: SIGN_IN_SUCCESS,
			token: fullToken,
			expiration,
			user
		});
		getReservations(fullToken, dispatch);
		fetchSources(fullToken, dispatch);
		if (window.FS) {
			window.FS.identify(user.userId, {
				name: `${user.firstName} ${user.lastName}`,
				email: user.email
			});
		}
	});
}

export function signOut(dispatch) {
	dispatch({ type: SIGN_OUT });
}

export function clearError(dispatch) {
	dispatch({ type: CLEAR_ERROR });
}

export function passwordCode(email, dispatch) {
	return sendPasswordReset(email);
}

export function resetPassword(email, password, code, dispatch) {
	return checkCode(email, password, code).then(response => {
		if (response.msg === "true") {
			resetPasswordAPI(email, password)
				.then(response => {
					return signIn(email, password, dispatch);
				})
				.catch(error => {
					throw Error(
						"We were unable to reset your password. Please try again later."
					);
				});
		} else {
			throw Error("Incorrect code. Please try again.");
		}
	});
}
