import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

export default class Footer extends PureComponent {
	render() {
		const { location } = this.props;
		if (location.pathname.indexOf("/s/") === 0) return null;
		if (location.pathname.indexOf("/hotel") === 0) return null;
		if (location.pathname.indexOf("/book") === 0) return null;
		if (location.pathname.indexOf("/reservations/") === 0) return null;
		return (
			<div className="bg-near-white pa4-ns pa3 flex flex-row-ns flex-column items-center-ns justify-between f7">
				<div className="flex flex-row-ns flex-column items-center-ns">
					<span className="f4 db fw6 mb0-ns mb3">TripFlip</span>

					<a
						href="/terms"
						target="_"
						className="link gray ml3-ns mb0-ns mb3"
					>
						Terms & Conditions
					</a>
					<Link
						to="/privacy"
						target="_"
						className="link gray ml3-ns mb0-ns mb3"
					>
						Privacy Policy
					</Link>
				</div>
				<div>
					<span className="gray">
						Copyright © 2019 TripFlip. All rights reserved.
					</span>
				</div>
			</div>
		);
	}
}
