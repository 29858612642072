import {
	fetchSources as fetchSourcesAPI,
	stripePayment as stripePaymentAPI,
	authorizeNetPayment as authorizeNetPaymentAPI
} from "../api";
import { SIGN_OUT } from "./auth";
import mixpanel from "mixpanel-browser";

export const PAYMENT_START = "PAYMENT_START";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";

export const SOURCES_SUCCESS = "SOURCES_SUCCESS";

export const CLEAR_ERROR = "CLEAR_ERROR";

export function fetchSources(token, dispatch) {
	return fetchSourcesAPI(token)
		.then(sources => {
			dispatch({ type: SOURCES_SUCCESS, sources });
			return sources;
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
		});
}

export function stripePayment(paymentInfo, token, dispatch) {
	dispatch({ type: PAYMENT_START });
	return stripePaymentAPI(paymentInfo, token)
		.then(reservation => {
			const { property, room, options, guest } = paymentInfo;
			dispatch({ type: PAYMENT_SUCCESS, property, room, options, guest });
			return reservation;
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
			const { property, room, options, guest } = paymentInfo;
			dispatch({ type: PAYMENT_FAILURE, property, room, options, guest });
			if (process.env.NODE_ENV !== "test") {
				mixpanel.track("Payment Failure", {
					propertyId: property.id,
					roomName: room.displayName,
					checkInDate: options.checkInDate,
					checkOutDate: options.checkOutDate,
					guestEmail: guest.emailAddress,
					error: error.message
				});
			}

			throw error;
		});
}

export function authorizeNetPayment(
	property,
	room,
	options,
	guest,
	card,
	token,
	dispatch
) {
	dispatch({ type: PAYMENT_START });
	return authorizeNetPaymentAPI(property, room, options, guest, card, token)
		.then(reservation => {
			dispatch({ type: PAYMENT_SUCCESS, property, room, options, guest });
			return reservation;
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
			dispatch({ type: PAYMENT_FAILURE, property, room, options, guest });
			if (process.env.NODE_ENV !== "test") {
				mixpanel.track("Payment Failure", {
					propertyId: property.id,
					roomName: room.displayName,
					checkInDate: options.checkInDate,
					checkOutDate: options.checkOutDate,
					guestEmail: guest.emailAddress
				});
			}
			throw error;
		});
}

export function clearError(dispatch) {
	dispatch({ type: CLEAR_ERROR });
}
