export const SHOW_GALLERY = "SHOW_GALLERY";
export const HIDE_GALLERY = "HIDE_GALLERY";
export const SET_CURRENT_IMAGE = "SET_CURRENT_IMAGE";

export function showGallery(dispatch) {
	dispatch({ type: SHOW_GALLERY });
}

export function hideGallery(dispatch) {
	dispatch({ type: HIDE_GALLERY });
}

export function setCurrentImage(image, dispatch) {
	dispatch({ type: SET_CURRENT_IMAGE, image });
}
