import { differenceInDays } from "date-fns";
import {
	FETCH_ROOMS_START,
	FETCH_ROOMS_SUCCESS,
	FETCH_ROOMS_FAILURE
} from "../actions/rooms";
import { SEARCH_SUCCESS } from "../actions/search";
import { roomKey } from "../utils";

export default function roomsReducer(
	state = {
		loading: false,
		rooms: {}
	},
	action
) {
	switch (action.type) {
		case FETCH_ROOMS_START:
			return {
				...state,
				loading: true
			};

		case FETCH_ROOMS_SUCCESS:
			return {
				...state,
				loading: false,
				rooms: {
					...state.rooms,
					[roomKey(action.hotel, action.options)]: action.rooms
				}
			};

		case FETCH_ROOMS_FAILURE:
			return {
				...state,
				loading: false
			};

		case SEARCH_SUCCESS:
			return {
				...state,
				rooms: action.results.reduce((acc, cur) => {
					acc[roomKey(cur.property, action.options)] = [
						roomFromResult(cur, action.options)
					];
					return acc;
				}, {})
			};

		default:
			return state;
	}
}

function roomFromResult(result, options) {
	const {
		property,
		room,
		rateCode,
		rateKey,
		tax,
		resortFee,
		price,
		supplierType,
		cancellationPolicy
	} = result;
	const { checkInDate, checkOutDate } = options;
	const { propertyType, termsAndConditions } = property;
	const {
		roomTypeId,
		roomTypeHistoryId,
		roomTypeCode,
		displayName,
		refundable
	} = room;

	const numberOfNights = differenceInDays(checkOutDate, checkInDate);
	const fakeRoomPrice = parseFloat(price * numberOfNights);
	const fakeRoomTax = parseFloat(tax.totalTax || 0);
	const fakeRoomTotalPrice = fakeRoomPrice + fakeRoomTax;
	const nightlyResortFee = resortFee / numberOfNights;
	const fakeRoomsRateInfo = [
		{
			roomPrice: fakeRoomPrice,
			roomTax: fakeRoomTax,
			roomTotalAmount: fakeRoomTotalPrice,
			rateKey,
			cancellationPolicy
		}
	];
	return {
		price: fakeRoomPrice,
		nightlyPrice: price,
		tax: fakeRoomTax,
		totalPrice: fakeRoomTotalPrice,
		source: propertyType, // Workaround because there is no source in response
		propertyType,
		supplierType,
		rateKey,
		rateCode,
		roomTypeId,
		roomTypeHistoryId,
		roomTypeCode,
		displayName,
		resortFee,
		nightlyResortFee,
		refundable,
		termsAndConditions,
		propertyTotalTaxDTO: tax,
		roomsRateInfo: fakeRoomsRateInfo
	};
}
