import { HOTEL_SUCCESS } from "../actions/hotels";
import { SEARCH_SUCCESS } from "../actions/search";

export default function hotelsReducer(state = {}, action) {
	switch (action.type) {
		case SEARCH_SUCCESS:
			return {
				...state,
				...action.results.reduce((acc, cur) => {
					acc[cur.property.id] = cur.property;
					return acc;
				}, {})
			};
		case HOTEL_SUCCESS:
			return {
				...state,
				[action.hotel.id]: action.hotel
			};
		default:
			return state;
	}
}
