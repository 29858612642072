import React, { PureComponent } from "react";

export default class Dropdown extends PureComponent {
	render() {
		const {
			id,
			openDropdownId,
			buttonComponent,
			dropdownComponent,
			preventButtonClose,
			preventFullscreen,
			openDropdown,
			closeDropdown
		} = this.props;
		if (!id) throw new Error("Dropdown ID required");
		const showDropdown = id === openDropdownId;

		return (
			<div>
				<div
					className="pointer z-999"
					onClick={event => {
						event.stopPropagation();
						!preventButtonClose && showDropdown
							? closeDropdown()
							: openDropdown(id);
					}}
				>
					{buttonComponent}
				</div>
				{showDropdown && dropdownComponent
					? preventFullscreen
						? this.renderDropdown()
						: this.renderFullscreenDropdown()
					: null}
			</div>
		);
	}

	renderDropdown() {
		const { dropdownComponent, dropdownClass } = this.props;
		return (
			<div
				className={`absolute left-auto-ns right-auto-ns left-0 right-0 ph0-ns ph3 mt3 z-9999 ${dropdownClass}`}
			>
				<div className="flex flex-column br2 shadow bg-white black overflow-x-hidden overflow-y-scroll no-scroll-bar ">
					{dropdownComponent}
				</div>
			</div>
		);
	}

	renderFullscreenDropdown() {
		const { dropdownComponent, dropdownClass, closeDropdown } = this.props;
		return (
			<div
				className={`dropdown-component z-999 bg-white black overflow-x-hidden overflow-y-scroll no-scroll-bar ${dropdownClass}`}
				onClick={event => event.stopPropagation()}
			>
				<div className="dn-ns db pa3 bb b--light-gray">
					<button
						className="pa0 bn bg-transparent pointer"
						onClick={() => closeDropdown()}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<line x1="18" y1="6" x2="6" y2="18" />
							<line x1="6" y1="6" x2="18" y2="18" />
						</svg>
					</button>
				</div>
				{dropdownComponent}
			</div>
		);
	}
}
