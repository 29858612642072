import { connect } from "react-redux";
import PureComponent from "./component";
import { setDates } from "../../actions/options";
import { performSearch } from "../../actions/search";
import { closeDropdown } from "../../actions/dropdown";

const mapStateToProps = (state, ownProps) => ({
	options: state.options
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	setDates: (checkInDate, checkOutDate) =>
		setDates(checkInDate, checkOutDate, dispatch),
	closeDropdown: () => closeDropdown(dispatch),
	performSearch: options => performSearch(options, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
