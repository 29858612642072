import { connect } from "react-redux";
import PureComponent from "./component";
import { openDropdown, closeDropdown } from "../../actions/dropdown";

const mapStateToProps = (state, ownProps) => ({
	openDropdownId: state.dropdown.openDropdownId
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	openDropdown: id => openDropdown(id, dispatch),
	closeDropdown: () => closeDropdown(dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
