import {
	HOVER_HOTEL,
	PREVIEW_HOTEL,
	SET_CURRENT_PAGE,
	SET_SORT_METHOD,
	SET_SEARCH_ON_MOVE
} from "../actions/ui";
import {
	SET_PRICE_FILTER,
	SET_STAR_FILTER,
	SET_BOUNDS_FILTER,
	SET_REVIEW_FILTER
} from "../actions/filters";
import { SET_LOCATION } from "../actions/options";

export default function uiReducer(
	state = {
		hoveredHotel: undefined,
		previewedHotel: undefined,
		searchOnMove: false,
		currentPage: 0,
		sortMethod: "savings"
	},
	action
) {
	switch (action.type) {
		case HOVER_HOTEL:
			return {
				...state,
				hoveredHotel: action.hotel
			};
		case PREVIEW_HOTEL:
			return {
				...state,
				previewedHotel: action.hotel
			};
		case SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.page
			};
		case SET_SORT_METHOD:
			return {
				...state,
				sortMethod: action.sortMethod,
				currentPage: 0
			};
		case SET_SEARCH_ON_MOVE:
			return {
				...state,
				searchOnMove: action.searchOnMove
			};

		case SET_PRICE_FILTER:
		case SET_STAR_FILTER:
		case SET_REVIEW_FILTER:
		case SET_BOUNDS_FILTER:
		case SET_LOCATION:
			return {
				...state,
				currentPage: 0
			};
		default:
			return state;
	}
}
