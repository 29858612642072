export const HOVER_HOTEL = "HOVER_HOTEL";
export const PREVIEW_HOTEL = "PREVIEW_HOTEL";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_SORT_METHOD = "SET_SORT_METHOD";
export const SET_SEARCH_ON_MOVE = "SET_SEARCH_ON_MOVE";
export const SHOW_GALLERY = "SHOW_GALLERY";
export const HIDE_GALLERY = "HIDE_GALLERY";

export function hoverHotel(hotel, dispatch) {
	dispatch({ type: HOVER_HOTEL, hotel });
}

export function previewHotel(hotel, dispatch) {
	dispatch({ type: PREVIEW_HOTEL, hotel });
}

export function setCurrentPage(page, dispatch) {
	dispatch({ type: SET_CURRENT_PAGE, page });
}

export function setSortMethod(sortMethod, dispatch) {
	dispatch({ type: SET_SORT_METHOD, sortMethod });
}

export function setSearchOnMove(searchOnMove, dispatch) {
	dispatch({ type: SET_SEARCH_ON_MOVE, searchOnMove });
}

export function showGallery(dispatch) {
	dispatch({ type: SHOW_GALLERY });
}

export function hideGallery(dispatch) {
	dispatch({ type: HIDE_GALLERY });
}
