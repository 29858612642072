import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import LocationSearch from "../LocationSearch";
import DateSearch from "../DateSearch";

class SearchBar extends PureComponent {
	handleSearch(event) {
		const { options, history, performSearch, closeDropdown } = this.props;
		event.preventDefault();
		performSearch(options);
		closeDropdown();
		history.push("/s/new-york-city");
	}

	render() {
		return (
			<div
				id="search-bar"
				className="flex flex-row-l flex-column w-100 items-center justify-center pt2"
			>
				<div className="ma2 w-100 mw5-5-ns nowrap">
					<LocationSearch
						hideClear={window.innerWidth > 480}
						hideBorder={true}
						onFocus={() => {
							if (window.innerWidth < 480) {
								document
									.getElementById("search-bar")
									.scrollIntoView({
										block: "start",
										behavior: "smooth"
									});
							}
						}}
					/>
				</div>
				<div className="ma2 w-100 mw5-5-ns nowrap">
					<DateSearch preventFullscreen={true} hideBorder={true} />
				</div>
				<button
					className="shadow ma2 br2 ba b--blue bg-blue hover-bg-dark-blue pointer flex items-center justify-center white fw6 w-auto-l w-100 mw5-5-ns ph3"
					onClick={event => this.handleSearch(event)}
					style={{ height: "50px" }}
				>
					Search
				</button>
			</div>
		);
	}
}

export default withRouter(SearchBar);
