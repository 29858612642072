import {
	SEARCH_START,
	SEARCH_SUCCESS,
	SEARCH_FAILURE
} from "../actions/search";

export default function searchReducer(
	state = {
		loading: false,
		results: undefined,
		error: undefined
	},
	action
) {
	switch (action.type) {
		case SEARCH_START:
			return {
				...state,
				loading: true,
				results: undefined,
				error: null
			};

		case SEARCH_SUCCESS:
			// if (isEqual(action.options, state.options)) {
			return {
				...state,
				loading: false,
				results: action.results
			};
		// }

		case SEARCH_FAILURE:
			// if (isEqual(action.options, state.options)) {
			return {
				...state,
				loading: false,
				results: [],
				error: "Could not load search results."
			};
		// }

		default:
			return state;
	}
}
