import { combineReducers } from "redux";
import options from "./options";
import filters from "./filters";
import search from "./search";
import book from "./book";
import hotels from "./hotels";
import rooms from "./rooms";
import locationSuggestions from "./locationSuggestions";
import dropdown from "./dropdown";
import modal from "./modal";
import gallery from "./gallery";
import ui from "./ui";
import auth from "./auth";
import user from "./user";
import reservations from "./reservations";
import payment from "./payment";

export default combineReducers({
	options,
	filters,
	search,
	book,
	hotels,
	rooms,
	locationSuggestions,
	dropdown,
	modal,
	gallery,
	ui,
	auth,
	user,
	reservations,
	payment
});
