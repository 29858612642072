import {
	SIGN_IN_START,
	SIGN_IN_SUCCESS,
	SIGN_IN_FAILURE,
	SIGN_OUT,
	CLEAR_ERROR
} from "../actions/auth";

const defaultState = {
	token: undefined,
	expiration: undefined,
	refreshToken: undefined,
	error: undefined
};

export default function authReducer(state = defaultState, action) {
	switch (action.type) {
		case SIGN_IN_START:
			return defaultState;
		case SIGN_IN_SUCCESS:
			return {
				token: action.token,
				expiration: action.expiration,
				refreshToken: action.refreshToken,
				error: undefined
			};
		case SIGN_IN_FAILURE:
			return {
				...defaultState,
				error: action.error
			};
		case SIGN_OUT:
			return defaultState;
		case CLEAR_ERROR:
			return {
				...state,
				error: undefined
			};
		default:
			return state;
	}
}
