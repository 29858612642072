import { connect } from "react-redux";
import PureComponent from "./component";
import { getReservations } from "../../actions/reservations";
import { closeDropdown } from "../../actions/dropdown";

const mapStateToProps = (state, ownProps) => ({
	reservations: state.reservations.reservations,
	loading: state.reservations.loadingReservations,
	token: state.auth.token
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getReservations: token => getReservations(token, dispatch),
	closeDropdown: () => closeDropdown(dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
