export function sanitizeImage(rawURL, large) {
	if (!rawURL) return null;
	const URL = `https:${
		rawURL
			.replace("https:", "")
			.replace("http:", "")
			.split(":")[0]
	}`;
	const smallURL = URL.replace("_z.jpg", "_b.jpg");
	const largeURL = smallURL.replace("_b.jpg", "_z.jpg");
	return large ? largeURL : smallURL;
}

export function getImageCaption(rawURL) {
	if (!rawURL) return null;
	const caption = rawURL.split("Caption:")[1];
	return caption;
}

export function sanitizeDescription(description) {
	return description;
}
