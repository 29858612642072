import { getReservationsApi, getReservationApi } from "../api";
import { SIGN_OUT } from "./auth";

export const RESERVATIONS_START = "RESERVATIONS_START";
export const RESERVATIONS_SUCCESS = "RESERVATIONS_SUCCESS";
export const RESERVATIONS_FAILURE = "RESERVATIONS_FAILURE";

export const RESERVATION_START = "RESERVATION_START";
export const RESERVATION_SUCCESS = "RESERVATION_SUCCESS";
export const RESERVATION_FAILURE = "RESERVATION_FAILURE";

export function getReservations(token, dispatch) {
	dispatch({ type: RESERVATIONS_START });
	return getReservationsApi(token)
		.then(reservations => {
			dispatch({ type: RESERVATIONS_SUCCESS, reservations });
			return reservations;
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
			dispatch({ type: RESERVATIONS_FAILURE });
			console.error(error);
		});
}

export function getReservation(confirmationCode, token, dispatch) {
	dispatch({ type: RESERVATION_START });
	return getReservationApi(confirmationCode, token)
		.then(reservation => {
			dispatch({ type: RESERVATION_SUCCESS, reservation });
			return reservation;
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
			dispatch({ type: RESERVATION_FAILURE });
			console.error(error);
		});
}
