import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { format, parse } from "date-fns";

export default class Component extends PureComponent {
	componentDidMount() {
		const { token, getReservations } = this.props;
		getReservations(token);
	}

	render() {
		const { reservations } = this.props;
		const upcomingReservations = reservations
			.filter(r => parse(r.checkInDate) >= new Date())
			.sort((a, b) => parse(a.checkInDate) - parse(b.checkInDate));
		const pastReservations = reservations
			.filter(r => parse(r.checkInDate) < new Date())
			.sort((a, b) => parse(b.checkInDate) - parse(a.checkInDate));
		return (
			<div className="pa3 flex-column items-start w-100 justify-between">
				<div className="w-100">
					<div className={pastReservations.length ? "mb4" : ""}>
						{this.renderReservations(
							"Upcoming Reservations",
							upcomingReservations
						)}
					</div>
					{this.renderReservations(
						"Past Reservations",
						pastReservations
					)}
					{this.renderEmpty()}
				</div>
			</div>
		);
	}

	renderReservations(title, reservations) {
		if (!reservations || !reservations.length) return null;
		const fmtStr = "MMM D";
		const fmtStrYear = "MMM D, YYYY";
		const { closeDropdown } = this.props;
		return (
			<div>
				<div className="bb b--light-gray pb3">
					<h1 className="mv0 f5 fw6">{title}</h1>
				</div>
				{reservations
					.filter(reservation => reservation.confirmationCode)
					.map(reservation => (
						<Link
							key={reservation.confirmationCode}
							to={`/reservations/${reservation.confirmationCode}`}
							className="link black"
							onClick={() => closeDropdown()}
						>
							<div className="pv3 bb b--light-gray w-100">
								<div className="flex items-center-ns w-100 pointer">
									<div className="mw4-ns mw3 w-100 mr3">
										<div className="aspect-ratio aspect-ratio--16x9-ns aspect-ratio--1x1">
											<div className="aspect-ratio--object flex">
												<div className="relative h-100 w-100">
													<div
														className="h-100 w-100 cover bg-center br2 bg-near-white"
														style={{
															backgroundImage: `url('${
																reservation
																	.property
																	.imageURL
															}')`
														}}
													/>
													<div
														className="absolute absolute--fill cover bg-center br2"
														style={{
															backgroundImage: `url('${
																reservation
																	.property
																	.imageLargeURL
															}')`
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="w-100">
										<span className="db fw7">
											{reservation.property.name}
										</span>
										<span className="db mv1 mid-gray">
											{reservation.city}
										</span>
										<span className="db mid-gray">
											{`${format(
												reservation.checkInDate,
												fmtStr
											)} - ${format(
												reservation.checkOutDate,
												fmtStrYear
											)}`}
										</span>
									</div>
								</div>
							</div>
						</Link>
					))}
			</div>
		);
	}

	renderEmpty() {
		const { reservations, loading } = this.props;
		if (loading && (!reservations || !reservations.length))
			return (
				<div className="flex flex-column items-start gray">
					Loading...
				</div>
			);
		if (!reservations) return null;
		if (!reservations.length)
			return (
				<div className="flex flex-column items-start">
					<h1 className="mt0 mb3 f5 fw6">Upcoming Reservations</h1>
					<span className="near-black db">
						You have no upcoming reservations.
					</span>
					<Link
						to="/"
						className="pa3 bg-blue white br2 shadow pointer db link fw5 mt4"
					>
						Start searching
					</Link>
				</div>
			);
	}
}
