import { OPEN_DROPDOWN, CLOSE_DROPDOWN } from "../actions/dropdown";

export default function searchReducer(
	state = {
		openDropdownId: undefined
	},
	action
) {
	switch (action.type) {
		case OPEN_DROPDOWN:
			return { ...state, openDropdownId: action.id };
		case CLOSE_DROPDOWN:
			return { ...state, openDropdownId: undefined };
		default:
			return state;
	}
}
