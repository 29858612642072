import {
	updateProfile as updateProfileAPI,
	updatePassword as updatePasswordAPI,
	getProfile as fetchProfileAPI
} from "../api";
import { SIGN_OUT } from "./auth";

export const FETCH_PROFILE_START = "FETCH_PROFILE_START";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export function fetchProfile(token, dispatch) {
	dispatch({ type: FETCH_PROFILE_START });
	return fetchProfileAPI(token)
		.then(profile => {
			dispatch({ type: FETCH_PROFILE_SUCCESS, profile });
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
			dispatch({ type: FETCH_PROFILE_FAILURE, error });
		});
}

export function updateProfile(user, token, dispatch) {
	dispatch({ type: UPDATE_PROFILE_START });
	return updateProfileAPI(user, token)
		.then(() => {
			dispatch({ type: UPDATE_PROFILE_SUCCESS });
			return fetchProfile(token, dispatch);
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
			dispatch({ type: UPDATE_PROFILE_FAILURE, error });
		});
}

export function updatePassword(email, password, token, dispatch) {
	dispatch({ type: UPDATE_PASSWORD_START });
	return updatePasswordAPI(email, password, token)
		.then(() => {
			dispatch({ type: UPDATE_PASSWORD_SUCCESS });
		})
		.catch(error => {
			if (error.message === "Unauthorized") dispatch({ type: SIGN_OUT });
			dispatch({ type: UPDATE_PASSWORD_FAILURE, error });
		});
}
