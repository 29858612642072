import React, { PureComponent } from "react";
import Dropdown from "../Dropdown";
import Input from "../Input";

const icons = {
	plus: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<line x1="12" y1="5" x2="12" y2="19" />
			<line x1="5" y1="12" x2="19" y2="12" />
		</svg>
	),
	minus: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<line x1="5" y1="12" x2="19" y2="12" />
		</svg>
	)
};

export default class GuestFilter extends PureComponent {
	constructor(props) {
		super(props);
		const { options } = this.props;
		const { noOfAdults, noOfChildren } = options.rooms[0];
		this.state = {
			noOfAdults,
			noOfChildren
		};
	}

	componentDidUpdate(prevProps) {
		// Redo search on close if guests have changed
		if (prevProps.dropdownOpen && !this.props.dropdownOpen) {
			const {
				options,
				performSearch,
				setNoOfAdults,
				setNoOfChildren
			} = this.props;
			const { noOfAdults, noOfChildren } = this.state;
			if (
				noOfAdults !== options.rooms[0].noOfAdults ||
				noOfChildren !== options.rooms[0].noOfChildren
			) {
				setNoOfAdults(noOfAdults);
				setNoOfChildren(noOfChildren);
				performSearch({
					...options,
					rooms: [{ ...options.rooms[0], noOfAdults, noOfChildren }]
				});
			}
		}
	}

	handleAddAdult() {
		const { noOfAdults } = this.state;
		const max = 4;
		this.setState({
			noOfAdults: Math.min(max, noOfAdults + 1)
		});
	}

	handleRemoveAdult() {
		const { noOfAdults } = this.state;
		const min = 1;
		this.setState({
			noOfAdults: Math.max(min, noOfAdults - 1)
		});
	}

	handleAddChild() {
		const { noOfChildren } = this.state;
		const max = 4;
		this.setState({
			noOfChildren: Math.min(max, noOfChildren + 1)
		});
	}

	handleRemoveChild() {
		const { noOfChildren } = this.state;
		const min = 0;
		this.setState({
			noOfChildren: Math.max(min, noOfChildren - 1)
		});
	}

	render() {
		const { closeDropdown } = this.props;
		const { noOfAdults, noOfChildren } = this.state;

		const isDefaultFilter =
			parseInt(noOfAdults) + parseInt(noOfChildren) === 1;
		const totalGuests = parseInt(noOfAdults) + parseInt(noOfChildren);
		const label = !isDefaultFilter
			? `${totalGuests} ${totalGuests > 1 ? "Guests" : "Guest"}`
			: "Guests";
		return (
			<Dropdown
				id="guestFilter"
				buttonComponent={<Input hideBorder={true}>{label}</Input>}
				dropdownClass="w-100 mw5-5-ns"
				dropdownComponent={
					<div className="pa3 f5 h-100 w-100 flex flex-column justify-between">
						<div>
							<div className="flex items-center justify-between mb3">
								<span>Adults</span>
								<div className="flex items-center justify-between">
									<button
										onClick={() => this.handleRemoveAdult()}
										className="bg-white b--blue ba br-pill blue flex justify-center items-center h2 w2 pointer"
									>
										{icons.minus}
									</button>
									<span className="db w2 mh3 tc">
										{noOfAdults}+
									</span>
									<button
										onClick={() => this.handleAddAdult()}
										className="bg-white b--blue ba br-pill blue flex justify-center items-center h2 w2 pointer"
									>
										{icons.plus}
									</button>
								</div>
							</div>
							<div className="flex items-center justify-between">
								<span>Children</span>
								<div className="flex items-center justify-between">
									<button
										onClick={() => this.handleRemoveChild()}
										className="bg-white b--blue ba br-pill blue flex justify-center items-center h2 w2 pointer"
									>
										{icons.minus}
									</button>
									<span className="db w2 mh3 tc">
										{noOfChildren}+
									</span>
									<button
										onClick={() => this.handleAddChild()}
										className="bg-white b--blue ba br-pill blue flex justify-center items-center h2 w2 pointer"
									>
										{icons.plus}
									</button>
								</div>
							</div>
						</div>
						<div className="flex items-center justify-between">
							{isDefaultFilter ? (
								<div />
							) : (
								<button
									onClick={() => {
										this.setState({
											noOfAdults: 1,
											noOfChildren: 0
										});
									}}
									className="static-ns fixed top-0 right-0 ma0-ns mt3 mr3 bn dark-gray f6 fw6 pointer self-end bg-transparent pa0"
								>
									<span className="db">Clear</span>
								</button>
							)}
							<button
								onClick={() => closeDropdown()}
								className="mt3 pa0-ns pa3 w-auto-ns w-100 br0-ns br2 bn bg-transparent-ns bg-blue blue-ns white f6 fw6 pointer self-end"
							>
								<span className="db-ns dn">Done</span>
								<span className="dn-ns db f5 fw6">
									Show results
								</span>
							</button>
						</div>
					</div>
				}
			/>
		);
	}
}
