import { getRooms } from "../api";

export const FETCH_ROOMS_START = "FETCH_ROOMS_START";
export const FETCH_ROOMS_SUCCESS = "FETCH_ROOMS_SUCCESS";
export const FETCH_ROOMS_FAILURE = "FETCH_ROOMS_FAILURE";

export function fetchRooms(hotel, options, dispatch) {
	if (!hotel || !options) return;
	dispatch({ type: FETCH_ROOMS_START, hotel, options });
	return getRooms(hotel, options)
		.then(rooms => {
			dispatch({ type: FETCH_ROOMS_SUCCESS, hotel, options, rooms });
			return rooms;
		})
		.catch(error =>
			dispatch({ type: FETCH_ROOMS_FAILURE, hotel, options, error })
		);
}
