export const SET_LOCATION = "SET_LOCATION";
export const SET_DATES = "SET_DATES";
export const SET_NO_OF_ADULTS = "SET_NO_OF_ADULTS";
export const SET_NO_OF_CHILDREN = "SET_NO_OF_CHILDREN";

export function setLocation(location, dispatch) {
	dispatch({ type: SET_LOCATION, location });
}

export function setDates(checkInDate, checkOutDate, dispatch) {
	dispatch({ type: SET_DATES, checkInDate, checkOutDate });
}

export function setNoOfAdults(noOfAdults, dispatch) {
	dispatch({ type: SET_NO_OF_ADULTS, noOfAdults });
}

export function setNoOfChildren(noOfChildren, dispatch) {
	dispatch({ type: SET_NO_OF_CHILDREN, noOfChildren });
}
