import { isEqual } from "lodash";

export function roomKey(hotel = {}, options = {}) {
	const { id } = hotel;
	const { checkInDate, checkOutDate, rooms = [{}] } = options;
	const { noOfAdults, noOfChildren, childrenAges } = rooms[0];
	return `${id} ${checkInDate} ${checkOutDate} ${noOfAdults} ${noOfChildren} ${childrenAges}`;
}

export function resultSavings(result) {
	const { price, marketPrice } = result;
	const savings = marketPrice - price;
	const savingsPercent = Math.floor((savings / marketPrice) * 100);
	return savingsPercent;
}

export function priceStats(results) {
	const sortedResults = [...results].sort((a, b) => a.price - b.price);
	const prices = sortedResults.map(result => parseFloat(result.price));
	const averagePrice = average(prices);
	const stdDev = standardDeviation(prices, averagePrice);
	const filteredResults = sortedResults.filter(
		result => result.price < averagePrice + stdDev
	);

	const numberOfBars = 64;
	const buckets = {};
	const lowPrice = 0;
	const highPrice = 1000; //parseFloat(filteredResults[highIndex].price);
	const range = highPrice - lowPrice;
	const interval = range / numberOfBars;

	let lowQuantity = 99999;
	let highQuantity = 0;
	filteredResults.forEach(result => {
		const bucket = parseInt(
			(parseFloat(result.price) - lowPrice) / interval
		);
		const newCount = (buckets[bucket] || 0) + 1;
		buckets[bucket] = newCount;
		if (newCount > highQuantity) {
			highQuantity = newCount;
		} else if (newCount < lowQuantity) {
			lowQuantity = newCount;
		}
	});
	const quantityRange = highQuantity - lowQuantity + 1;

	return {
		lowPrice: parseFloat(lowPrice),
		highPrice: parseFloat(highPrice),
		averagePrice,
		stdDev,
		interval,
		buckets,
		lowQuantity,
		highQuantity,
		quantityRange,
		numberOfBars
	};

	function standardDeviation(values, avg) {
		var squareDiffs = values.map(function(value) {
			var diff = value - avg;
			var sqrDiff = diff * diff;
			return sqrDiff;
		});

		var avgSquareDiff = average(squareDiffs);

		var stdDev = Math.sqrt(avgSquareDiff);
		return stdDev;
	}

	function average(data) {
		var sum = data.reduce(function(sum, value) {
			return sum + value;
		}, 0);

		var avg = sum / data.length;
		return avg;
	}
}

// Don't return true here excpet for the last return. Otherwise filters will not be stacked
export function filterResults(results, filters) {
	if (!results || !filters) return undefined;
	const {
		stars,
		reviews,
		bounds,
		minPrice,
		maxPrice,
		neighborhoods,
		properties
	} = filters;
	return results.filter(result => {
		// Search within map
		if (bounds) {
			const { lat, lng } = result.property;
			if (lng < bounds.west || lng > bounds.east) return false;
			if (lat < bounds.south || lat > bounds.north) return false;
		}

		// If star filters, only show matching
		if (
			!isEqual(stars, {}) &&
			!stars[Math.floor(result.property.starRating)]
		) {
			return false;
		}

		// If review filters, only show matching
		if (!isEqual(reviews, {})) {
			const minRating = Object.keys(reviews).sort((a, b) => a - b)[0];
			const { userRating } = result.property;
			const { score } = result.property.reviews || {};
			const actualScore = score || userRating * 20;
			if (!actualScore) return false;
			if (actualScore < minRating) return false;
		}

		// Price
		if (minPrice || maxPrice) {
			const outOfRange =
				Math.floor(result.price) <= minPrice ||
				Math.ceil(result.price) >= maxPrice;
			if (outOfRange) return false;
		}

		// Neighborhoods
		if (!isEqual(neighborhoods, {})) {
			if (!result.property.zoneName) return false;
			const zones = result.property.zoneName
				.split("/")
				.map(zone => zone.trim());

			let matchesFilters = false;
			for (var i = 0; i < zones.length; i++) {
				const zone = zones[i];
				if (neighborhoods[zone]) {
					matchesFilters = true;
				}
			}

			if (!matchesFilters) return false;
		}

		// Neighborhoods
		if (!isEqual(properties, {})) {
			if (!properties[result.property.id]) {
				return false;
			}
		}

		return true;
	});
}

// Sorting

export const sortFunctions = {
	savings: resultsBySavings,
	price: resultsByPrice,
	stars: resultsByStarRating,
	rating: resultsByUserRating
};

function resultsBySavings(a, b) {
	const savingsA = parseFloat(a.price) / 5 / parseFloat(a.marketPrice);
	const savingsB = parseFloat(b.price) / 5 / parseFloat(b.marketPrice);

	if (savingsA > savingsB) {
		return 1;
	}
	if (savingsA < savingsB) {
		return -1;
	}

	// Savings are equal -- sort by rating instead
	// debugger;

	return 0;
}

function resultsByPrice(a, b) {
	const priceA = parseFloat(a.price);
	const priceB = parseFloat(b.price);

	if (priceA > priceB) {
		return 1;
	}
	if (priceA < priceB) {
		return -1;
	}
	return 0;
}

function resultsByStarRating(a, b) {
	const starRatingA = parseFloat(a.property.starRating);
	const starRatingB = parseFloat(b.property.starRating);

	if (starRatingA > starRatingB) {
		return -1;
	}
	if (starRatingA < starRatingB) {
		return 1;
	}
	return 0;
}

function resultsByUserRating(a, b) {
	const reviewsA = a.property.reviews || {};
	const reviewsB = b.property.reviews || {};
	const ratingA = a.property.userRating * 20 || 0;
	const ratingB = b.property.userRating * 20 || 0;
	const scoreA = parseFloat(reviewsA.score) || 0;
	const scoreB = parseFloat(reviewsB.score) || 0;
	const userRatingA = parseFloat(scoreA || ratingA);
	const userRatingB = parseFloat(scoreB || ratingB);

	if (userRatingA > userRatingB) {
		return -1;
	}
	if (userRatingA < userRatingB) {
		return 1;
	}
	return 0;
}
