export const OPEN_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export function openModal(id, dispatch) {
	dispatch({ type: OPEN_MODAL, id });
}

export function closeModal(dispatch) {
	dispatch({ type: CLOSE_MODAL });
}
