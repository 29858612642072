import { format, addDays } from "date-fns";
import {
	SET_LOCATION,
	SET_DATES,
	SET_NO_OF_ADULTS,
	SET_NO_OF_CHILDREN
} from "../actions/options";

export const defaultState = {
	location: "New York, New York, United States Of America",
	checkInDate: format(addDays(Date(), 14), "YYYY-MM-DD"),
	checkOutDate: format(addDays(Date(), 16), "YYYY-MM-DD"),
	rooms: [{ noOfAdults: 2, noOfChildren: 0, childrenAges: "0" }],
	starRatings: ["1", "2", "3", "4", "5"]
};

export default function optionsReducer(state = defaultState, action) {
	switch (action.type) {
		case SET_LOCATION:
			return {
				...state,
				location: action.location
			};

		case SET_DATES:
			return {
				...state,
				checkInDate: action.checkInDate,
				checkOutDate: action.checkOutDate
			};

		case SET_NO_OF_ADULTS:
			return {
				...state,
				rooms: [
					{
						...state.rooms[0],
						noOfAdults: parseInt(action.noOfAdults)
					}
				]
			};

		case SET_NO_OF_CHILDREN:
			return {
				...state,
				rooms: [
					{
						...state.rooms[0],
						noOfChildren: parseInt(action.noOfChildren)
					}
				]
			};

		default:
			return state;
	}
}
