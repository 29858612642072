import { OPEN_MODAL, CLOSE_MODAL } from "../actions/modal";

export default function searchReducer(
	state = {
		openModalId: undefined
	},
	action
) {
	switch (action.type) {
		case OPEN_MODAL:
			return { ...state, openModalId: action.id };
		case CLOSE_MODAL:
			return { ...state, openModalId: undefined };
		default:
			return state;
	}
}
