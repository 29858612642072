import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import LocationSearch from "../LocationSearch";
import DateSearch from "../DateSearch";
import GuestFilter from "../GuestFilter";

class SearchBar2 extends PureComponent {
	handleSearch(event) {
		const { options, history, performSearch, closeDropdown } = this.props;
		event.preventDefault();
		performSearch(options);
		closeDropdown();
		history.push("/s/new-york-city");
	}

	render() {
		return (
			<div
				id="search-bar"
				className="flex w-100 items-center justify-start"
			>
				<div className="w-100 mw5-5-ns nowrap mr3">
					<LocationSearch
						hideClear={window.innerWidth > 480}
						hideBorder={true}
						searchOnClick={true}
						onFocus={() => {
							if (window.innerWidth < 480) {
								document
									.getElementById("search-bar")
									.scrollIntoView({
										block: "start",
										behavior: "smooth"
									});
							}
						}}
					/>
				</div>
				<div className="nowrap mr3">
					<DateSearch
						preventFullscreen={true}
						searchOnClose={true}
						hideBorder={true}
					/>
				</div>
				<div className="nowrap">
					<GuestFilter />
				</div>
			</div>
		);
	}
}

export default withRouter(SearchBar2);
