import { connect } from "react-redux";
import PureComponent from "./component";
import { performSearch } from "../../actions/search";
import { setLocation } from "../../actions/options";
import { fetchLocationSuggestions } from "../../actions/locationSuggestions";
import { closeDropdown } from "../../actions/dropdown";

const mapStateToProps = (state, ownProps) => ({
	options: state.options,
	locationSuggestions: state.locationSuggestions
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	setLocation: location => setLocation(location, dispatch),
	fetchLocationSuggestions: input =>
		fetchLocationSuggestions(input, dispatch),
	performSearch: options => performSearch(options, dispatch),
	closeDropdown: () => closeDropdown(dispatch)
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
