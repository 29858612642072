import { search } from "../api";
import mixpanel from "mixpanel-browser";

export const SEARCH_START = "SEARCH_START";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE";

export function performSearch(options, dispatch) {
	const { location, checkInDate, checkOutDate, rooms } = options;
	if (!location || !checkInDate || !checkOutDate || !rooms) {
		console.error("Missing options", Object.keys(options));
		return;
	}
	dispatch({ type: SEARCH_START, options });
	mixpanel.track("Search", { ...options });
	return search(options)
		.then(results => {
			dispatch({ type: SEARCH_SUCCESS, results, options });
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: SEARCH_FAILURE, options });
		});
}

export function setFilters(filters, dispatch) {
	dispatch({
		type: "SET_FILTERS",
		filters
	});
}

export function setSort(sort, dispatch) {
	dispatch({
		type: "SET_SORT",
		sort
	});
}

export function clearRecent(dispatch) {
	dispatch({
		type: "CLEAR_RECENT"
	});
}

export function setSelectedProperty(property, dispatch) {
	dispatch({
		type: "SET_SELECTED_PROPERTY",
		property
	});
}

export function setSelectedRoom(room, dispatch) {
	dispatch({
		type: "SET_SELECTED_ROOM",
		room
	});
}

export function setCardNumber(cardNumber: string, dispatch) {
	dispatch({
		type: "SET_CARD_NUMBER",
		cardNumber
	});
}

export function setCardExpiration(expirationDate, dispatch) {
	dispatch({
		type: "SET_CARD_EXPIRATION",
		expirationDate
	});
}

export function setCardCVC(cvc, dispatch) {
	dispatch({
		type: "SET_CARD_CVC",
		cvc
	});
}
