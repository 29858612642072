import React, { PureComponent } from "react";
import Dropdown from "../Dropdown";
import Input from "../Input";

const icons = {
	place: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className="mr2 h1 w1 min-w1"
		>
			<path
				d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"
				fill="currentColor"
			/>
			<circle cx="12" cy="10" r="3" fill="white" stroke="white" />
		</svg>
	)
};

export default class Component extends PureComponent {
	constructor(props) {
		super(props);
		this.input = React.createRef();
		this.state = {
			value: this.props.options.location
		};
	}

	handleLocationChange(event) {
		const { fetchLocationSuggestions } = this.props;
		event.preventDefault();
		const { value } = event.target;
		fetchLocationSuggestions(value);
		this.setState({ value: value });
	}

	render() {
		const { large, hideClear, hideBorder, onFocus } = this.props;
		const { value } = this.state;
		return (
			<Dropdown
				id="locationSuggestions"
				preventButtonClose={true}
				preventFullscreen={true}
				buttonComponent={
					<Input
						onClick={event => {
							this.input.current.select();
						}}
						hideBorder={hideBorder}
					>
						<div
							className="flex"
							// onBlur={alert("blur")}
						>
							<input
								ref={this.input}
								className="w-100 bn br2 fw6 pa0 pointer truncate"
								value={value}
								autoCorrect="false"
								spellCheck={false}
								placeholder="Where to?"
								onClick={() => this.input.current.select()}
								onFocus={() => {
									this.input.current.select();
									if (onFocus) {
										onFocus();
									}
								}}
								onChange={event =>
									this.handleLocationChange(event)
								}
							/>
							{!hideClear && value.length ? (
								<button
									className="pa0 bn bg-transparent ml2 moon-gray pointer"
									onClick={() => this.setState({ value: "" })}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										viewBox="4 4 16 16"
										fill="none"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<line x1="18" y1="6" x2="6" y2="18" />
										<line x1="6" y1="6" x2="18" y2="18" />
									</svg>
								</button>
							) : null}
						</div>
					</Input>
				}
				dropdownComponent={this.renderSuggestions()}
				dropdownClass={`w-100 ${large ? "mw6" : "mw5-5-ns"}`}
			/>
		);
	}

	renderSuggestions() {
		const {
			options,
			setLocation,
			performSearch,
			searchOnClick,
			closeDropdown
		} = this.props;
		const suggestions = this.getSuggestions();

		if (!suggestions.length) return null;

		return (
			<div className="br2 w-100 truncate">
				{suggestions.slice(0, 5).map(suggestion => (
					<div
						key={suggestion}
						className="pa3 bb b--light-gray f6 fw5 flex items-center pointer truncate"
						onClick={() => {
							setLocation(suggestion);
							closeDropdown();
							this.setState({
								value: suggestion
							});
							if (searchOnClick) {
								performSearch({
									...options,
									location: suggestion
								});
							}
						}}
					>
						{icons.place}
						<span className="truncate">{suggestion}</span>
					</div>
				))}
			</div>
		);
	}

	getSuggestions() {
		const { locationSuggestions } = this.props;
		const { value } = this.state;
		if (!locationSuggestions || !value) return [];
		const suggestions = locationSuggestions[value];
		if (suggestions) return suggestions;
		// If not loaded, show suggestions for substrings
		for (var length = value.length - 1; length > 0; length--) {
			const substr = value.substring(0, length);
			if (locationSuggestions[substr]) return locationSuggestions[substr];
		}
		return [];
	}
}
